const prefix = "/api";
const prefix2 = "/s_api";

let hostname = window.location.hostname;

export default {
  wechat: `${prefix}/basic/jssdk`,
  // 国家区号前缀
  countries: `${prefix}/basic/countries`,
  // 邮箱注册
  register: `${prefix}/user/email_sign_up`,
  // 手机注册
  register_mobile: `${prefix}/user/mobile_sign_up`,
  // 快捷注册
  quick_register: `${prefix}/user/quick_sign_up`,
  // 快捷登录
  quick_authorize: `${prefix}/user/quick_authorize`,
  // 快捷登录二次验证
  quick_authorize_advance: `${prefix}/user/quick_authorize_advance`,
  // 授权登录第一步
  oauth2_authorize: `${prefix}/oauth2/authorize`,
  // 邮箱登录step1
  login: `${prefix}/login/login`,
  // 手机登录step1
  login_mobile: `${prefix}/user/mobile_authorize`,
  // 手机或邮箱登录，step1
  login_all: `${prefix}/user/authorize`,
  // 登录step2
  login_step2: `${prefix}/user/authorize_advance`,
  // 登出
  logout: `${prefix}/user/authorize_cancel`,
  // 未登录状态 邮箱验证码 get_verify_code
  send_email_verify_code: `${prefix}/common/send_email_verify_code`,
  // 登录时，二次验证邮箱验证码
  send_email_verify_code_authorize_advance: `${prefix}/user/send_email_verify_code/authorize_advance`,
  // 登录时，二次验证手机验证码
  send_sms_verify_code_authorize_advance: `${prefix}/user/send_sms_verify_code/authorize_advance`,
  // 已登录状态 邮箱验证码 get_verify_code
  send_email2_verify_code: `${prefix}/user/send_email_verify_code`,
  // 未登录状态 手机验证码
  send_sms_verify_code: `${prefix}/common/send_sms_verify_code`,
  // 已登录状态 手机验证码
  send_sms2_verify_code: `${prefix}/user/send_sms_verify_code`,

  // 手机找回密码
  mobile_find_password: `${prefix}/user/mobile_find_pwd_check`,
  // 邮箱找回密码
  email_find_password: `${prefix}/user/email_find_pwd_check`,
  // 重置密码
  reset_password: `${prefix}/user/find_pwd`,
  // 修改密码
  update_pwd: `${prefix}/user/update_pwd`,

  // userinfo
  userinfo: `${prefix}/user/get_base_info`,
  get_account: `${prefix}/account/get`, // 账户资产
  get_asset: `${prefix}/asset/get`, // 币余额查询
  // 获取ga数据,key img
  ga_info: `${prefix}/user/before_bind_ga`,
  // 绑定ga
  bind_ga: `${prefix}/user/bind_ga`,
  // bind mobile
  bind_mobile: `${prefix}/user/bind_mobile`,
  // bind email
  bind_email: `${prefix}/user/bind_email`,
  // 登录日志
  authorize_log: `${prefix}/user/authorize_log`,
  // 选择自选
  favorite_create: `${prefix}/user/favorite/create`,
  // 取消自选
  favorite_cancel: `${prefix}/user/favorite/cancel`,
  // 实名认证
  kyc: `${prefix}/user/verify`,
  // 实名信息
  verify_info: `${prefix}/user/verify_info`,
  // api
  api_list: `${prefix}/user/api_keys`,
  api_create: `${prefix}/user/api_key/create`,
  api_update: `${prefix}/user/api_key/update_ips`,
  api_del: `${prefix}/user/api_key/delete`,
  api_status: `${prefix}/user/api_key/change_status`, // 启用，禁用
  // 用户等级
  user_level_info: `${prefix}/user/get_userlevel_info`,
  // 等级配置信息
  user_level_configs: `${prefix}/user/get_userlevel_configs`,

  // 基础接口
  config: `${prefix2}/basic/config`, // 币，币对，币选项
  rate: `${prefix2}/basic/rates`, // 汇率
  rate2: `${prefix}/quote/v1/rates`, // 汇率2
  get_all_coins: `${prefix}/basic/tokens`, // 获取所有币种
  symbols: `${prefix}/basic/symbols`, // 所有币对列表
  id_card_type: `${prefix}/basic/id_card_type`, // 证件类型
  upload_image: `${prefix}/user/verify/upload_image`, // 图片上传
  quote_tokens: `${prefix}/basic/quote_tokens`, // 首页，行情页 币栏目
  get_ws_token: `${prefix}/user/get_ws_token`, // 订单ws连接前，握手接口
  index_config: `${prefix2}/basic/index_config`, // 首页配置信息
  download_info: `${prefix2}/basic/download/info`,
  token_info: `${prefix2}/basic/token`, // token信息
  custom_kv: `${prefix2}/basic/custom_kv`, // 获取配置信息（区分语言）
  // 设置用户自定义配置
  set_custom_config: `${prefix}/user/set_settings`,
  get_custom_config: `${prefix}/user/get_settings`,

  
  subordinateList: `${prefix}/acting/subordinateList`, 
  updateCommission: `${prefix}/acting/updateCommission`,
};
