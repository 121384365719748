/**
 * type: html_slow, png_slow, api_slow, websocket_slow, png_error, api_error, websocket_error
 * url: png/api/websocket url, urlencoded
 * cost: cost time, in seconds
 * code: error code, if any
 * msg: error msg, if any
 */

import request from "./request";
import api from "../config/api";

let data = [];
let timer = null;
function report(type = "default_type", url, cost = "", code = "", msg = "") {
  data.push({
    type,
    url: encodeURIComponent(url || window.location.href),
    cost,
    code,
    msg
  });
}

async function update() {
  
  
}
window.g_report = report;
window.addEventListener("load", update, false);
export default report;
