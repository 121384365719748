const local = `/${localStorage.lang}/m`; // :lang or '' 多语言是否配置在路径中
const webLocal = `/${localStorage.lang}`; // :lang or '' 多语言是否配置在路径中

export default {
  // 未登录，允许访问的地址
  noLogin: [
    "login",
    'error',
  ],
  index: `${local}/`,
  // 登录
  login: `${local}/login`,
  login1: `${webLocal}/login`,
};
