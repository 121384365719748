import React from "react";
import { Router, Route, Switch, Redirect } from "dva/router";
import dynamic from "dva/dynamic";
import route_map from "./config/route_map";

const indexConfig = window.WEB_CONFIG || {};
function RouterConfig({ history, app }) {
  // 404
  const NotFountRC = dynamic({
    app,
    component: () => import("./routes/404"),
  });
  
  // login 登录
  const LoginRC = dynamic({
    app,
    models: () => [import("./models/user")],
    component: () => import("./routes/user/login"),
  });
  // login 登录
  const LoginRC1 = dynamic({
    app,
    models: () => [import("./models/user")],
    component: () => import("./routes/user/login"),
  });
  
  // 首页
  const IndexRC = dynamic({
    app,
    models: () => [import("./models/user")],
    component: () => import("./routes/index/index"),
  });
  

  return (
    <Router history={history}>
      <Switch>
        {/* 首页 */}
        <Route exact path={route_map.index} component={IndexRC} />
        {/* 登录 */}
        <Route exact path={route_map.login} component={LoginRC} />
        {/* 登录 */}
        <Route exact path={route_map.login1} component={LoginRC} />
        
        {/* 首页 */}
        <Route exact path={route_map.index2} component={IndexRC} />
        
      </Switch>
    </Router>
  );
}

export default RouterConfig;
